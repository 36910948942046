import React from 'react'
import './about.css'
import ME from '../../assets/niku.jpg'
import { FaAward } from 'react-icons/fa'
import { IoIosSchool } from 'react-icons/io'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='about-image' />
          </div>
        </div>
        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>Software Engineer at OCA Solutions.</small>{' '}
              <small>Freelance Software Developer.</small>
              <small>University Projects.</small>
            </article>

            <article className='about__card1'>
              <IoIosSchool className='about__icon' />
              <h5>Education</h5>
              <p>
                <small>
                  Bachelor of Science in Computer Engineering. Udemy, Probit
                  Academy, PER PROGRAMERA, WordPress, Kutiza Graphic Design, ICK
                </small>{' '}
              </p>
            </article>

            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Projects</h5>
              <small>
                20+ Projects, including Courses projects, and uni projects
              </small>
            </article>
          </div>
          <p>
            Hello there :) - I’m Kreshnik Zabërgja, a passionate Software
            Engineer based in Vushtrria, Kosovo. I hold a degree in Computer
            Engineering from the University of Isa Boletini. I work as a Full
            Stack Engineer at OCA Solutions, a leading company from Switzerland.
            My expertise lies in the MEAN stack, with a particular focus on
            Angular. I also code in React JS and C# with ASP.NET Core.
            <span className='poet'>
              In addition to my technical skills, I’m also a poet and the proud
              author of
              <span className='poet_color'> "Lule Xhenneti"</span>. I bring
              creativity and a unique perspective to both my programming and
              writing, always striving for excellence in every project I
              undertake.
            </span>
          </p>
          <a href='#contact' className='btn btn-primary'>
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  )
}

export default About
